import React from 'react';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Databuilder - Coming Soon 👷</h1>
        <p>Building better places. 🏡</p>
      </header>
      <Footer />
    </div>
  );
}

function Footer() {
  return (
    <footer className="App-footer">
      <p>Databuilder Limited is registered in England and Wales under company number 15906801. Our registered office is 91 Parklands Avenue, Leamington Spa, England, CV32 7BP </p>
    </footer>
  );
}

export default App;
